import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { uiAppsState } from "../../atoms/app.atoms";
import { Icon, Menu, MenuItem } from "semantic-ui-react";
import './Home.scss'

function Home() {
  const apps = useRecoilValue(uiAppsState);
  const renderLink = (app) => {
    return (
      <MenuItem key={app.id} as={Link} to={`/sites/${app.app_id}/`}>
        <Icon name="th" size="big" /> 
        <span>{app.name}</span>
      </MenuItem>
    );
  };

  return (
      <div className="home-wrapper">
          <div className="section-title">
              <div className="label">Cloud Platform Apps</div>
          </div>
          <div>
          <Menu vertical>
              {apps && apps.map(renderLink)}
          </Menu>
      </div>
     </div>
  )
}

export default Home;
