import {getToken, resolveCurrentUser} from "../apis/auth.api";

export const handleMessage = (siteId, history) => (event) => {
    const {source, data} = event;
    if (data && data.event) {
        const resp: any = {
            id: data.id
        }

        switch (data.event) {
            case 'FETCH_USER_TOKEN':
                resp.payload = getToken();
                break;
            case 'FETCH_USER_PROFILE':
                resp.payload = resolveCurrentUser();
                break;
            case 'ROUTE_CHANGE':
                history.replace(`/sites/${siteId}${data.payload}`)
                break;
            default:
                resp.payload = 'Unknown Event';
        }

        source.postMessage(resp, '*');
    }
}
