import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button, Checkbox, Dropdown, DropdownItemProps, Form, Icon, Message } from "semantic-ui-react";
import { addEntityAssociation, fetchEntityAssociation, fetchEntities } from "../../../../apis/acl.api";
import { getEntities, getAssociationSchema } from "../../../../services/acl.service";
import { DatePicker } from "../../../common/DatePicker";
import { formatError } from "../../../../utils/error.utils";
import { getDisplayName, getLabel } from "../../../../utils/entity-type.utils";

import "./EntityAssociationsList.scss";

type EntityAssociationsListProps = {
    record: any;
    entityType: string;
    associationType: string;
    lookup: string;
    descriptionGenerator?: (entity: any) => string | undefined;
    includeSystemEntities?: boolean | undefined;
    readOnly?: boolean | undefined;
};

export function EntityAssociationsList(props: EntityAssociationsListProps) {
    const { record: entity, entityType, associationType, lookup, descriptionGenerator, includeSystemEntities, readOnly } = props;

    const recursive = entityType === associationType;
    const { addToast } = useToasts();
    const [filteredEntities, setFilteredEntities] = useState<any[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<DropdownItemProps[]>([]);
    const [selectedEntityId, setSelectedEntityId] = useState<string>("");
    const [associationsChanged, setAssociationsChanged] = useState(false);

    const [associatedEntities, setAssociatedEntities] = useState<any[]>([]);
    const schema = getAssociationSchema(entityType, associationType);
    const allowDeny = schema.some((x) => x.id === "allowDeny");
    const additionalProperties = schema.some((x) => x.id !== "allowDeny");
    const [loading, setIsLoadingDropdown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const associations = await fetchEntityAssociation(entityType, entity.id, associationType);
            const associatedIds = associations.map((x) => x[associationType]);
            const associatedEntities = await getEntities(associationType, associatedIds);
            const data = associations.map((association) => {
                const associatedEntity = associatedEntities.find((x) => x.id === association[associationType]);
                return {
                    ...association,
                    ...associatedEntity,
                };
            });
            setIsLoading(false);

            data.sort((a, b) => a[lookup].localeCompare(b[lookup]));
            setAssociatedEntities(data);
            setAssociationsChanged(false);
        })();
    }, [entityType, associationType, entity.id, lookup]);

    const handleChange = async (value) => {
        const query = {
            filterKey: lookup,
            filterValue: value,
            limit: 10,
            sort: lookup,
        };

        setIsLoadingDropdown(true);
        const entities = value ? await fetchEntities(associationType, query) : [];
        setIsLoadingDropdown(false);

        setFilteredEntities(entities);
        refreshOptions(entities, associatedEntities);
    };

    const refreshOptions = (entities: any[], associations) => {
        const dropdownOptions = entities
            .filter((e) => !e.$system || includeSystemEntities)
            .map((e) => {
                const alreadyAdded = !!associations.find((a) => a.id === e.id) || e.default;
                return {
                    key: e.id,
                    value: e.id,
                    text: e[lookup],
                    description: descriptionGenerator && descriptionGenerator(e),
                    disabled: alreadyAdded,
                    icon: alreadyAdded ? "circle check" : "plus circle",
                };
            });
        setDropdownOptions(dropdownOptions);
    };

    const addItem = (_e, data) => {
        const entity = filteredEntities.find((e) => e.id === data.value);

        if (entity) {
            const newAssociatedEntities = [...associatedEntities, entity];
            newAssociatedEntities.sort((a, b) => a[lookup].localeCompare(b[lookup]));

            setAssociatedEntities(newAssociatedEntities);
            setAssociationsChanged(true);

            refreshOptions(filteredEntities, newAssociatedEntities);
        }

        //reset selected value so it's not marked as active on result list
        setSelectedEntityId("");
    };

    const removeItem = (e, index) => {
        e.stopPropagation();
        associatedEntities.splice(index, 1);

        setAssociatedEntities([...associatedEntities]);
        setAssociationsChanged(true);

        refreshOptions(filteredEntities, associatedEntities);
    };

    const saveAssociations = async () => {
        try {
            setIsSaving(true);
            await addEntityAssociation(
                entityType,
                entity.id,
                associationType,
                associatedEntities.map((item) => ({
                    ...Object.fromEntries(Object.entries(item).filter(([key, _]) => schema.some((i) => i.id === key))),
                    [associationType]: item.id,
                    deny: item.deny === true,
                }))
            );
            addToast(`${associationType.toUpperCase()}(s) were successfully updated on the ${entityType.toUpperCase()}`, {
                appearance: "success",
            });
            setAssociationsChanged(false);
        }
        catch (e: any) {
            const errorMessage = formatError("Saving associations failed", e);
            addToast(errorMessage, { appearance: "error" });
        }
        setIsSaving(false);
    };

    const renderAllowDeny = (item) => {
        if (!allowDeny || item.$system) // can't deny system entities UM-126
            return;

        return <Checkbox toggle label="Deny" className="red" disabled={readOnly} checked={item.deny} onChange={() => toggleDeny(item)} />
    }

    const toggleDeny = (item) => {
        setProperty(item, "deny", !item.deny);
        setAssociatedEntities((items) => [...items]);
    };

    const setProperty = (item, name, value) => {
        item[name] = value;
        setAssociatedEntities((items) => [...items]);
        setAssociationsChanged(true);
    };

    const renderInput = (property, item) => {
        const value = item[property.id];
        if (property.type === "date") {
            return <DatePicker name={property.id} disabled={readOnly} value={value} size="mini" onChange={(date) => setProperty(item, property.id, date)} />
        }
    }

    const renderPropertyList = (item) => {
        return schema
            .filter((p) => p.id !== "allowDeny")
            .map((property) => {
                return (
                    <Form.Field inline key={property.id}>
                        <label>{property.label}</label>
                        {renderInput(property, item)}
                    </Form.Field>
                );
            });
    };

    return (
        <div className="cs-filter-ctx">
            <div className="section-subheader">
                <div className="label">{recursive ? `Child ${associationType}` : associationType}s</div>
                <div className="actions">
                    {!readOnly && (
                        <Button disabled={!associationsChanged || isSaving} size="tiny" basic={!associationsChanged} color={associationsChanged ? "blue" : undefined} onClick={saveAssociations}>
                            Save
                        </Button>
                    )}
                </div>
            </div>

            {!readOnly && (
                <Dropdown
                    selection
                    search
                    fluid
                    loading={loading}
                    onChange={addItem}
                    onSearchChange={(_e, data) => handleChange(data.searchQuery)}
                    options={dropdownOptions}
                    selectOnNavigation={false}
                    selectOnBlur={false}
                    text={`Add ${associationType}s...`}
                    value={selectedEntityId}
                />
            )}

            {!isLoading && (
                <div className="associations">
                    {associatedEntities.map((item, index) => (
                        <div className="item" key={item.id}>
                            <div className="header">
                                <div className="label">
                                    {!readOnly && <Icon name="times circle" onClick={(e) => removeItem(e, index)} />}
                                    {item[lookup]}
                                </div>
                                {descriptionGenerator && <div className="description">{descriptionGenerator(item)}</div>}
                                {renderAllowDeny(item)}
                            </div>
                            {additionalProperties && <Form className="additional-properties">{renderPropertyList(item)}</Form>}
                        </div>
                    ))}

                    {associatedEntities.length === 0 && (
                        <Message>No {getLabel(associationType, false)} are associated with this {entityType}.</Message>
                    )
                    }
                </div>
            )}
        </div >
    );
}

export default EntityAssociationsList;
