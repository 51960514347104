import React from "react";
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import Administer from "./administer/Administer";
import Schema from "./schema/Schema";
import Settings from "./settings/Settings";

function Acl() {
    let {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/administer/:entityType`}>
                <Administer/>
            </Route>
            <Route path={`${path}/schema/:schemaType`}>
                <Schema/>
            </Route>
            <Route path={`${path}/settings`}>
                <Settings/>
            </Route>
        </Switch>
    )
}

export default Acl;
