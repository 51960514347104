function KnowledgeCenterList({videos, adminUser, moduleMap, parseVideoUrl, editVideo, deleteVideo}) {
    if (videos?.length === 0) {
        return <div className="empty-message">No resources found!</div>
    } else {
        return <div className="list-ctx ui container">
            {videos.map(video => (
                <a key={video.id} className="video-ctx" href={parseVideoUrl(video.url)} target="_blank">
                    <div className="thumbnail">
                        {adminUser && <div className="thumbnail-button">
                            <button onClick={(event) => editVideo(event, video)}><i className="edit outline icon"></i></button>
                            <button onClick={(event) => deleteVideo(event, video)}><i className="trash icon"></i></button>
                        </div>}
                        <img src={video.thumbnail}/>
                    </div>
                    <div className="details">
                        <div className="title">{video.title}</div>
                        <div className="module"><a className={`ui ${moduleMap[video.module]} tag label`}>{video.module}</a></div>
                        <div className="description">{video.description}</div>
                    </div>
                </a>
            ))}
        </div>
    }
}

export default KnowledgeCenterList;
