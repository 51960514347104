import { Link, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Divider, Menu } from "semantic-ui-react";
import { selectedAppLoadTimeState, selectedAppState } from "../../atoms/app.atoms";
import { ENV_NAME } from "../../utils/path.utils";
import Logo from './Logo'; 

import AppsDropdown from "./AppsDropdown";

import './Header.scss';
import { SettingsDropdown } from "./SettingsDropdown";

function Header() {
    const location = useLocation();
    const setLoadTime = useSetRecoilState(selectedAppLoadTimeState);
    const app = useRecoilValue(selectedAppState);

    const renderEnvName = () => {
        if (ENV_NAME) {
            return <div className="env-name">{ENV_NAME}</div>
        }
    };

    const forceRefresh = () => {
        setTimeout(() => setLoadTime(Date.now()), 100);
    };

    const renderSiteLink = () => {
        if (app && location.pathname.startsWith('/sites/')) {
            return (
                <Link to={`/sites/${app.app_id}`} className="item site-title" onClick={forceRefresh}>{app.name}</Link>
            );
        }
    };

    return (
        <Menu fixed="top" className="cs-header-wrapper">
            <Menu.Item as={Link} to="/" className="logo-wrapper" onClick={forceRefresh}>
                <Logo />
                {renderEnvName()}
            </Menu.Item>
            {renderSiteLink()}
            <Menu.Menu position="right">
                <AppsDropdown />
                <SettingsDropdown />
            </Menu.Menu>
        </Menu>
    )
}

export default Header;

