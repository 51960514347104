import { atom } from "recoil";

export const aclTypesState = atom<any[]>({
    key: "aclTypesState",
    default: [
        {
            id: "application",
            label: "Applications",
        },
        {
            id: "user",
            label: "Users",
        },
        {
            id: "key",
            label: "API Keys",
        },
        {
            id: "group",
            label: "Groups",
        },
        {
            id: "package",
            label: "Packages",
        },
        {
            id: "permission",
            label: "Permissions",
        },
    ],
});
