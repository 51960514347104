import {orderBy} from 'lodash';

import './Sites.scss';

function Sites({sites}) {

    const renderSites = () => {
        return orderBy(sites, 'name').map(site => (
            <tr key={site.app_id}>
                <td>{site.app_id}</td>
                <td>{site.name}</td>
                <td className="center aligned">{site.web ? 'Yes' : 'No'}</td>
                <td className="center aligned">{site.api ? 'Yes' : 'No'}</td>
                <td>Sandeep Aramalla</td>
            </tr>
        ));
    }

    return (
        <div className="ui container manage-sites-wrapper">
            <div className="section-title"><div className="label">Manage Sites</div></div>

            <table className="ui table">
                <thead>
                <tr>
                    <th style={{width: 150}}>Id</th>
                    <th>Name</th>
                    <th className="center aligned" style={{width: 75}}>UI</th>
                    <th className="center aligned" style={{width: 75}}>API</th>
                    <th style={{width: 150}}>Support</th>
                </tr>
                </thead>
                <tbody>
                {renderSites()}
                </tbody>
            </table>
        </div>
    );
}

export default Sites;
