import { useState } from "react";
import { parseUrl } from "../../../utils/path.utils";

import './Apis.scss';

function Apis({ sites }) {
    const [selected, setSelected] = useState<any>();

    sites = sites.filter(site => site.api);
    if (!selected && sites.length > 0) {
        setSelected(sites[0]);
    }

    const renderSites = () => {
        return sites.map(site => (
            <div key={site.app_id} className={`list-item ${selected === site ? 'selected' : ''}`} onClick={() => setSelected(site)}>
                <div className="name">{site.name}</div>
                <div className="id">ID: {site.app_id}</div>
                <div className="support">Support: Sandeep Aramalla</div>
            </div>
        ));
    }

    return (
        <div className="manage-apis-wrapper">
            <div className="section-title"><div className="label">Manage APIs</div></div>
            <div className="content-ctx">
                <div className="list-ctx">
                    {renderSites()}
                </div>
                <div className="frame-ctx">
                    {selected && <iframe src={`${parseUrl()}/api/${selected.app_id}/docs`}></iframe>}
                </div>
            </div>
        </div>
    )
}

export default Apis;
