import { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import { exportEntitiesToExcel, exportAssociationsToExcel, getAssociatedTypes, importEntitiesFromExcel, importAssociationsFromExcel } from "../../../../services/acl.service";
import { getUploadFile } from "../../../../utils/file.utils";
import { getLabel as getEntityTypeLabel } from "../../../../utils/entity-type.utils";
import { formatError } from "../../../../utils/error.utils";

import "./Entity.scss";

export type EntityActionsProps = {
    entityType: string,
    selectedEntities: { [key: string]: boolean },
    sortField: string,
    sortDesc: boolean,
    filter: any,
    canEdit: boolean,
    onEntityAdd: () => void,
    onSearch: (filter: any) => void,
    onImportCompleted: () => Promise<void>,
    onImportFailed: () => Promise<void>,
}

function EntityActions(props: EntityActionsProps) {
    const { addToast } = useToasts();
    const { entityType, canEdit } = props;

    const [bulkActionInProgress, setIsBulkActionInProgress] = useState(false);


    const handleExport = async () => {
        const { sortField, sortDesc, filter: filterConfig } = props;
        const query = {
            sort: sortField,
            sortDesc: sortDesc,
            filterKey: filterConfig?.key,
            filterValue: filterConfig?.value,
            ids: getSelectedEntityIds(),
            limit: 100000,
        };

        try {
            setIsBulkActionInProgress(true);
            await exportEntitiesToExcel(entityType, query);

            addToast("Records successfully exported", {
                appearance: "success",
            });
        } catch (e) {
            const errorMessage = formatError("Export failed", e);
            addToast(errorMessage, { appearance: "error" });
        }
        setIsBulkActionInProgress(false);
    };

    const getSelectedEntityIds = () => {
        const { selectedEntities } = props;
        return Object.keys(selectedEntities).filter((key) => selectedEntities[key] === true);
    };

    const handleAssociationExport = async (associatedEntityType: string) => {
        setIsBulkActionInProgress(true);

        const selectedEntityIds = getSelectedEntityIds();

        try {
            await exportAssociationsToExcel(entityType, associatedEntityType, selectedEntityIds);
            addToast("Records successfully exported", {
                appearance: "success",
            });
        } catch (e) {
            const errorMessage = formatError("Export failed", e);
            addToast(errorMessage, { appearance: "error" });
        }
        setIsBulkActionInProgress(false);
    };

    const handleImport = async () => {
        const { onImportCompleted, onImportFailed } = props;
        try {
            const file = await getUploadFile();
            if (!file) return;

            setIsBulkActionInProgress(true);

            await importEntitiesFromExcel(entityType, file);
            await onImportCompleted();

            addToast("Records successfully imported", {
                appearance: "success",
            });
        } catch (e) {
            const errorMessage = formatError("Import failed", e);
            addToast(errorMessage, { appearance: "error" });
            await onImportFailed();
        }
        setIsBulkActionInProgress(false);
    };

    const handleAssociationsImport = async (associatedEntityType: string) => {
        try {
            const file = await getUploadFile();
            if (!file) return;

            setIsBulkActionInProgress(true);
            await importAssociationsFromExcel(entityType, associatedEntityType, file);
            addToast("Records successfully imported", {
                appearance: "success",
            });
        } catch (e) {
            const errorMessage = formatError("Import failed", e);
            addToast(errorMessage, { appearance: "error" });
        }
        setIsBulkActionInProgress(false);
    };

    const handleAdd = () => {
        const { onEntityAdd } = props;
        onEntityAdd();
    }

    const renderDropdownMenu = () => {
        const { canEdit } = props;
        const associatedTypes = getAssociatedTypes(entityType);
        const noEntitiesSelected = getSelectedEntityIds().length === 0;
        const label = getEntityTypeLabel(entityType);

        return (
            <Dropdown icon="cog" loading={bulkActionInProgress} className="icon button blue" disabled={bulkActionInProgress}>
                <Dropdown.Menu>
                    <Dropdown item text="Export" floating direction="left" pointing="left" icon="dropdown left">
                        <Dropdown.Menu>
                            <Dropdown.Item text={label} icon="download" onClick={handleExport} />
                            {associatedTypes.length > 0 && <Dropdown.Header content="Associations" />}
                            {associatedTypes.map((associatedType) => (
                                <Dropdown.Item key={associatedType} icon="download" text={getEntityTypeLabel(associatedType)} disabled={noEntitiesSelected} onClick={() => handleAssociationExport(associatedType)} />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {canEdit && <Dropdown item text="Import" floating direction="left" pointing="left" icon="dropdown left">
                        <Dropdown.Menu>
                            <Dropdown.Item text={label} icon="upload" onClick={handleImport} />
                            {associatedTypes.length > 0 && <Dropdown.Header content="Associations" />}
                            {associatedTypes.map((associatedType) => (
                                <Dropdown.Item key={associatedType} icon="upload" text={getEntityTypeLabel(associatedType)} onClick={() => handleAssociationsImport(associatedType)} />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    return <div className="actions">
        <div className="ui buttons">
            <button className="ui blue button" onClick={handleAdd} disabled={!canEdit}>
                Add {entityType.toUpperCase()}
            </button>
            {renderDropdownMenu()}
        </div>
    </div>
}

export default EntityActions;
