import { merge } from "lodash";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { aclTypesState } from "../../../../atoms/acl.atoms";
import { PropertySchema, SchemaField } from "../../../../types/acl.types";

function toDropdownOption(value: any) {
    return {
        text: value,
        value,
    };
}

function SchemaFieldForm({ field, onSubmit, onCancel }) {
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<SchemaField>({ defaultValues: field });
    const [fieldOptions, setFieldOptions] = useState(field.options?.map(toDropdownOption) || []);
    const watchType = watch("type");

    const requiredOptions = [
        {
            value: false,
            text: "No",
        },
        {
            value: true,
            text: "Yes",
        },
    ];

    const typeOptions = [
        {
            value: "string",
            text: "STRING",
        },
        {
            value: "number",
            text: "NUMBER",
        },
        {
            value: "boolean",
            text: "BOOLEAN",
        },
        {
            value: "date",
            text: "DATE",
        },
        {
            value: "enum",
            text: "ENUM",
        },
        {
            value: "association",
            text: "ASSOCIATION",
        },
        {
            value: "secret",
            text: "SECRET",
        },
    ];

    const entityTypeOptions = useRecoilValue(aclTypesState).map((t) => ({
        text: t.label,
        value: t.id,
    }));

    const onValid = async (values: PropertySchema) => {
        // values for disabled input does are not submitted
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/disabled
        const mergedValues: PropertySchema = merge({}, field, values);

        if (mergedValues.type !== "string" && mergedValues.type !== "secret") {
            mergedValues.pattern = undefined;
        }

        if (mergedValues.type !== "enum") {
            mergedValues.options = undefined;
        }

        if (mergedValues.type !== "association") {
            mergedValues.associationEntityType = undefined;
            mergedValues.associationKey = undefined;
        }

        onSubmit(mergedValues);
    };

    const addFieldOption = (_event, data: DropdownProps) => {
        setFieldOptions([...fieldOptions, toDropdownOption(data.value)]);
    };

    return (
        <div className="cs-schema-field-form">
            <div className="section-header">
                <div className="label">Edit Field</div>
            </div>
            <form className="ui form" onSubmit={handleSubmit(onValid)}>
                <div className="field">
                    <label>Id</label>
                    <input
                        {...register("id", {
                            required: true,
                            pattern: /^(?![0-9])[a-zA-Z0-9$_]+$/,
                        })}
                        disabled={!!field.id || field.system}
                        placeholder="Enter ID"
                    />
                    {errors?.id?.type === "required" && <div className="ui pointing red basic label">Id is required</div>}
                    {errors?.id?.type === "pattern" && <div className="ui pointing red basic label">Id must be in valid format</div>}
                </div>
                <div className="field">
                    <label>Name</label>
                    <input {...register("label", { required: true })} placeholder="Enter Label" />
                    {errors.label && <div className="ui pointing red basic label">Label is required</div>}
                </div>
                <div className="field">
                    <label>Type</label>
                    <Controller
                        name="type"
                        control={control}
                        defaultValue="string"
                        render={({ field: { onChange, ...rest } }) => {
                            return <Dropdown selection options={typeOptions} disabled={field.system} onChange={(_e, data) => onChange(data.value)} {...rest} />;
                        }}
                    />
                </div>
                {watchType === "association" && (
                    <div className="field">
                        <label>Association Type</label>
                        <Controller
                            name="associationEntityType"
                            control={control}
                            render={({ field: { onChange, ...rest } }) => {
                                return <Dropdown selection options={entityTypeOptions} disabled={field.system} onChange={(_e, data) => onChange(data.value)} {...rest} />;
                            }}
                        />
                    </div>
                )}{" "}
                {watchType === "association" && (
                    <div className="field">
                        <label>Association Key</label>
                        <input {...register("associationKey", { required: false })} disabled={field.system} placeholder="Optional association key" />
                    </div>
                )}
                <div className="field">
                    <label>Required</label>
                    <Controller
                        name="required"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, ...rest } }) => {
                            return <Dropdown selection options={requiredOptions} disabled={field.system} onChange={(_e, data) => onChange(data.value)} {...rest} />;
                        }}
                    />
                </div>
                <div className="field">
                    <label>Read-only</label>
                    <Controller
                        name="readonly"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, ...rest } }) => {
                            return <Dropdown selection options={requiredOptions} disabled={field.system} onChange={(_e, data) => onChange(data.value)} {...rest} />;
                        }}
                    />
                </div>
                <div className="field">
                    <label>Unique</label>
                    <Controller
                        name="unique"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, ...rest } }) => {
                            return <Dropdown selection options={requiredOptions} disabled={field.system} onChange={(_e, data) => onChange(data.value)} {...rest} />;
                        }}
                    />
                </div>
                {watchType === "string" && (
                    <div className="field">
                        <label>Pattern</label>
                        <input {...register("pattern")} disabled={field.system} placeholder="Enter Pattern" />
                    </div>
                )}
                {watchType === "enum" && (
                    <div className="field">
                        <label>Options</label>
                        <Controller
                            name="options"
                            control={control}
                            defaultValue={field.options}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur } }) => {
                                return (
                                    <Dropdown
                                        search
                                        selection
                                        multiple
                                        allowAdditions
                                        disabled={field.system}
                                        options={fieldOptions}
                                        defaultValue={field.options}
                                        onChange={(_e, data) => onChange(data.value)}
                                        onBlur={onBlur}
                                        onAddItem={addFieldOption}
                                    />
                                );
                            }}
                        />    
                        {errors?.options?.type === "required" && <div className="ui pointing red basic label">Options are required</div>}
                    </div>
                )}
                <div className="buttons-ctx">
                    <button className="ui green button" type="submit">
                        Submit
                    </button>
                    <button className="ui red button" type="submit" onClick={onCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}

export default SchemaFieldForm;
