import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {selectedAppLoadTimeState, selectedAppState} from "../atoms/app.atoms";
import {handleMessage} from "../utils/message.utils";
import './Site.scss';
import {resolveSiteUrl} from "../utils/path.utils";

function Site() {
    const location = useLocation();
    const history = useHistory();
    const site = useRecoilValue(selectedAppState);
    const loadTime = useRecoilValue(selectedAppLoadTimeState);
    const [siteUrl, setSiteUrl] = useState<string>('');

    useEffect(() => {
        if (site) {
            const url = resolveSiteUrl(site, loadTime, location);
            console.log(`site url ${url}`);
            setSiteUrl(url);

            const handler = handleMessage(site.app_id, history);
            window.addEventListener("message", handler);
            return () => window.removeEventListener("message", handler);
        } else {
            setSiteUrl('');
        }
    }, [site, loadTime]);

    return (
        <div className="cs-site-wrapper">
            {siteUrl && <iframe src={siteUrl}></iframe>}
        </div>
    );
}

export default Site;
