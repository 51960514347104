import { Suspense } from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";

import "semantic-ui-css/semantic.min.css";
import App from "./components/App";
import Spinner from "./components/common/Spinner";

ReactDOM.render(
    <Suspense fallback={<Spinner color="#2185d0" size="75px" />}>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </Suspense>,
    document.getElementById("root")
);
