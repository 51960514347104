export function Pagination({ current, onPrevClick, onNextClick }) {
    const isPrevDisabled = current === 0;

    return (
        <div className="ui pagination menu tiny">
            <a
                className={`icon item ${isPrevDisabled ? "disabled" : ""}`}
                onClick={isPrevDisabled ? undefined : onPrevClick}
            >
                <i className="left small chevron icon"></i>
            </a>
            <a className="item">Page: {current + 1}</a>
            <a className="icon item" onClick={onNextClick}>
                <i className="right small chevron icon"></i>
            </a>
        </div>
    );
}
