import { Icon, Table } from "semantic-ui-react";
import { getAssociatedTypes } from "../../../../services/acl.service";
import { formatUTCDate } from "../../../../utils/date-utils";
import { getDisplayName } from "../../../../utils/entity-type.utils";
import { ActionIcon } from "../../../common/ActionIcon";
import "./Entity.scss";

export type EntityTableProps = {
    entityType: string,
    entities: any[],
    entityProperties: any[],
    selectedEntities: { [key: string]: boolean },
    sortField: string,
    sortDesc: boolean,
    canAdminister: boolean,
    canShowAccess: boolean,
    onEntitySelect: (entity: any, isSelected: boolean) => void,
    onEntityEdit: (entity: any) => void,
    onEntityDelete: (entity: any) => void,
    onEntityShowAssociations: (entity: any) => void,
    onEntityShowAccess: (entity: any) => void,
    onSortChange: (column: string, desc: boolean) => void
}

function EntityTable(props: EntityTableProps) {

    const isSelected = (entityId: string) => {
        return !!props.selectedEntities[entityId];
    };

    const handleSelect = (entity: any) => {
        const { onEntitySelect } = props;

        onEntitySelect(entity, !isSelected(entity.id));
    }
    const handleSortChange = (column: any) => {
        const { sortField, sortDesc, onSortChange } = props;

        if (sortField === column) {
            onSortChange(sortField, !sortDesc);
        }
        else
            onSortChange(column, false);
    }

    const formatValue = (value, type) => {
        if (type === "boolean") return value ? "True" : "False";
        if (type === "date") return formatUTCDate(value);

        return value;
    }

    const renderHeader = () => {
        const { entityProperties, sortField, sortDesc } = props;
        return <Table.Row>
            <Table.HeaderCell className="column-checkbox"></Table.HeaderCell>
            <Table.HeaderCell className="column-actions"></Table.HeaderCell>
            {entityProperties.map((item) => (
                <Table.HeaderCell className={`column-property-${item.id}`} key={`column-${item.id}`} onClick={() => handleSortChange(item.id)}>
                    {item.label}
                    {item.id === sortField && <Icon name={sortDesc ? "sort alphabet descending" : "sort alphabet ascending"} />}
                </Table.HeaderCell>
            ))}
        </Table.Row>
    }

    const renderList = () => {
        const { entities, entityType, entityProperties, canAdminister, canShowAccess } = props;
        const { onEntityEdit, onEntityDelete, onEntityShowAssociations, onEntityShowAccess } = props;
        const hasAssociations = getAssociatedTypes(entityType).length > 0;

        return entities.map((entity, entityIndex) => {
            const canEdit = !entity.$system && canAdminister;
            const canDelete = !entity.$system && canAdminister;
            const selected = isSelected(entity.id);

            return (
                <Table.Row key={`entity-${entityIndex}`} active={selected} >
                    <Table.Cell textAlign="center" onClick={() => handleSelect(entity)}>
                        <input type="checkbox" onChange={() => handleSelect(entity)} checked={selected} />
                    </Table.Cell>
                    <Table.Cell textAlign="center" className="column-actions">
                        <Icon disabled={!canEdit} name="edit" className="outline" onClick={() => onEntityEdit(entity)} />
                        <ActionIcon icon="trash" disabled={!canDelete} onConfirm={() => onEntityDelete(entity)}
                            message={`Are you sure you want to delete "${getDisplayName(entityType, entity)}" ${entityType}?`} />
                        {hasAssociations && <Icon name="share alternate square" onClick={() => onEntityShowAssociations(entity)} />}
                        {canShowAccess && <Icon name="info circle" onClick={() => onEntityShowAccess(entity)} />}
                    </Table.Cell>
                    {entityProperties.map((property) => (
                        <Table.Cell disabled={entity.$system} key={`field-${entity.id}-${property.id}`}>
                            {formatValue(entity[property.id], property.type)}
                        </Table.Cell>
                    ))}
                </Table.Row>
            );
        });
    };

    return <Table selectable celled sortable>
        <Table.Header>{renderHeader()}</Table.Header>
        <Table.Body>{renderList()}</Table.Body>
    </Table>
}

export default EntityTable;
