import { atom, selector } from "recoil";
import { autoLogin, resolveCurrentUser } from "../apis/auth.api";

const defaultAuthSelector = selector({
    key: "defaultAuthState",
    get: async () => {
        const auth = resolveCurrentUser();
        if (auth) {
            return auth;
        }

        return await autoLogin();
    },
});

const authState = atom<any>({
    key: "authState",
    default: defaultAuthSelector,
});

export default authState;
