import {Link} from "react-router-dom";
import {useSetRecoilState, useRecoilValue} from "recoil";
import {Button, Dropdown, Icon} from "semantic-ui-react";

import {clearToken} from "../../apis/auth.api";
import authState from "../../atoms/auth.atoms";
import {hasPermissionQuery} from "../../atoms/permissions.atoms";

export function SettingsDropdown() {
    const setAuth = useSetRecoilState(authState);

    const canSeeACLEntities = useRecoilValue(hasPermissionQuery("platform:acl:read_access"));
    const canSeeACLSchema = useRecoilValue(hasPermissionQuery("platform:acl:manage_schema"));
    const canSeeACLSettings = useRecoilValue(hasPermissionQuery("platform:acl:manage_cache"));
    const showUserManagementSection = canSeeACLEntities || canSeeACLSchema || canSeeACLSettings;

    const signOut = async (event) => {
        event.stopPropagation();
        await clearToken();
        setAuth(null);
    }

    const trigger = (
        <span>
            <Icon name="user circle"/> Settings <Icon name="dropdown" />
        </span>
    );

    return <Dropdown item trigger={trigger} icon={null}  className="cs-menu-dropdown">
        <Dropdown.Menu>
            <Dropdown.Header>Management</Dropdown.Header>
            <Dropdown.Item text="API Management" to="/manage/apis" as={Link}/>
            {showUserManagementSection && (
                <>
                    <Dropdown.Divider/>
                    <Dropdown.Header>User Entitlements</Dropdown.Header>
                    {canSeeACLEntities && (<Dropdown.Item text="Administer Permissions" to="/manage/acls/administer/user" as={Link}/>)}
                    {canSeeACLSchema && (<Dropdown.Item text="Manage Schema" to="/manage/acls/schema/user" as={Link}/>)}
                    {canSeeACLSettings && (<Dropdown.Item text="Settings" to="/manage/acls/settings" as={Link}/>)}
                </>
            )}
            <Dropdown.Divider/>
            <Dropdown.Header>Resources</Dropdown.Header>
            <Dropdown.Item text="My Profile" to="/my/profile" as={Link}/>
            <Dropdown.Divider/>
            <Dropdown.Item className="button">
                <Button icon="sign-out" content="Logout" onClick={signOut} basic color="red" fluid/>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}
