import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { format, set, isSameDay } from "date-fns";

export const TIMEZONE = "US/Eastern";
export const DATE_FORMAT = "dd MMM yyyy, hh:mm a 'EST'";

export function formatUTCDate(date: string | Date | null | undefined) {
    if (!date) return "";

    const convertedDate = utcToZonedTime(date, TIMEZONE);
    return format(convertedDate, DATE_FORMAT)
}

export function convertUtcToDefaultTZ(date: string | Date | null | undefined): Date | null {
    if (!date) return null;

    return utcToZonedTime(date, TIMEZONE);
}

export function convertDefaultTZToUtc(date: string | Date | null | undefined) {
    if (!date) return null;

    return zonedTimeToUtc(date, TIMEZONE);
}

export function shouldDefaultToEndOfADay(propertyName: string | undefined) {
    return propertyName?.toLocaleLowerCase().endsWith("enddate") === true;
}

export function setDefaultTime(date: Date, propertyName: string | undefined) {
    if (shouldDefaultToEndOfADay(propertyName)) {
        return set(date, {
            hours: 23,
            minutes: 59,
            seconds: 59,
            milliseconds: 999
        });
    }
    else {
        return set(date, {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        });
    }
}

export function sameDay(a: Date | null, b: Date | null) {
    return a && b && isSameDay(a, b);
}