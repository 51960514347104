import { selector, selectorFamily } from "recoil";
import { fetchPermissions } from "../apis/user.api";
import authState from "./auth.atoms";

type PermissionCodes =
    | "platform:acl:read_access"
    | "platform:acl:administer_applications"
    | "platform:acl:administer_groups"
    | "platform:acl:administer_keys"
    | "platform:acl:administer_packages"
    | "platform:acl:administer_permissions"
    | "platform:acl:administer_users"
    | "platform:acl:manage_cache"
    | "platform:acl:manage_schema";

export type EntityType = "application" | "group" | "key" | "user" | "package" | "permission";

export const permissionsQuery = selector<any[]>({
    key: "permissionsQuery",
    get: async ({ get }) => {
        const user = get(authState);
        const permissions = !user ? [] : await fetchPermissions("platform");
        return permissions;
    },    
});

export const hasPermissionQuery = selectorFamily({
    key: "hasPermissionQuery",
    get:
        (permissionCode: PermissionCodes) =>
        ({ get }) => {
            return get(permissionsQuery).some((p) => p.code === permissionCode);
        },
});

export const canEditEntityQuery = selectorFamily({
    key: "canEditEntityQuery",
    get:
        (entityType: EntityType) =>
        ({ get }) => {
            switch (entityType) {
                case "application":
                    return get(hasPermissionQuery("platform:acl:administer_applications"));
                case "group":
                    return get(hasPermissionQuery("platform:acl:administer_groups"));
                case "key":
                    return get(hasPermissionQuery("platform:acl:administer_keys"));
                case "package":
                    return get(hasPermissionQuery("platform:acl:administer_packages"));
                case "permission":
                    return get(hasPermissionQuery("platform:acl:administer_permissions"));
                case "user":
                    return get(hasPermissionQuery("platform:acl:administer_users"));
                default:
                    return false;
            }
        },
});
