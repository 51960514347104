import { useEffect, useState } from "react";
import { fetchEntities, fetchSchema } from "../apis/acl.api";
import { getDefaultOrder } from "../services/acl.service";

export function useSchemaState(type) {
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await fetchSchema(type);
            setSchema(data);
        })();
    }, [type]);

    return [schema, setSchema];
}

type RefreshProps = {
    page?: number | undefined;
    filter?: { key?: string; value?: string } | undefined;
    sort?: string | undefined;
    sortDesc?: boolean | undefined;
};


export function useEntityState(type) {
    const defaultSort = getDefaultOrder(type);
    const [sortField, setSortField] = useState(defaultSort);
    const [sortDesc, setSortDesc] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [entities, setEntities]: any = useState(null);
    const [filterConfig, setFilterConfig]: any = useState(null);

    const pageSize = 100;

    const refresh = async (props: RefreshProps = {}) => {
        let newIndex = props.page ?? pageIndex;
        let newFilterConfig = props.filter ?? filterConfig;
        let newSort = props.sort ?? sortField;
        let newSortDesc = props.sortDesc ?? sortDesc;

        const data = await fetchEntities(type, {
            sort: newSort,
            sortDesc: newSortDesc,
            offset: newIndex * pageSize,
            limit: pageSize,
            filterKey: newFilterConfig?.key,
            filterOperator: newFilterConfig?.operator,
            filterValue: newFilterConfig?.value,
        });

        setEntities(data);

        setSortField(newSort);
        setSortDesc(newSortDesc);
        setFilterConfig(newFilterConfig);
        setPageIndex(newIndex);
    };

    useEffect(() => {
        refresh({
            page: 0,
            sort: defaultSort,
            sortDesc: false,
            filter: {}
        });
    }, [type]);

    return [
        entities,
        pageIndex,
        sortField,
        sortDesc,
        filterConfig,
        refresh,
    ];
}
