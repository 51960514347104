import {useEffect} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {useRecoilState, useRecoilValue} from "recoil";
import {selectedAppIdState, uiAppsState} from "../atoms/app.atoms";

function SiteTracker({children}) {
    const location = useLocation();
    const history = useHistory();
    const apps = useRecoilValue(uiAppsState);
    const [appId, setAppId] = useRecoilState(selectedAppIdState);

    const findApp = (id) => {
        return apps ? apps.find(app => app.app_id === id) : null;
    };

    useEffect(() => {
        const pathname = location.pathname;

        if (pathname.indexOf('/sites/') !== -1) {
            const pathStrip = pathname.replace('/sites/', '');
            const index = pathStrip.indexOf('/');
            const id = pathStrip.substring(0, index === -1 ? pathStrip.length : index);
            const app = findApp(id);
            if (id && app) {
                console.log(`Settings ${id}`);
                setAppId(id);
                return;
            }

            if (apps.length > 0) {
                let previousAppId = localStorage.getItem('APP_ID');
                const previousApp = findApp(previousAppId);
                previousAppId = previousApp ? previousAppId : null;
                const newId = appId || previousAppId || apps[0].app_id;
                history.push(`/sites/${newId}`);
            }
        }

    }, [apps, appId, location.pathname])

    return children;
}

export default SiteTracker;
