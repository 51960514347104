import { merge, toNumber } from "lodash";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { getEntityProperties } from "../../../../services/acl.service";
import { saveEntity } from "../../../../apis/acl.api";
import { formatError } from "../../../../utils/error.utils";
import { getDisplayName } from "../../../../utils/entity-type.utils";
import { Button, Checkbox, Form, Input, Label, Message } from "semantic-ui-react";
import { PropertySchema } from "../../../../types/acl.types";
import { DatePicker } from "../../../common/DatePicker";
import { Select } from "../../../common/Select";

function EntityForm({ type, close, schema, entity }) {
    const {
        handleSubmit,
        getValues,
        setValue,
        formState: { errors, dirtyFields },
        control,
    } = useForm({ defaultValues: entity });
    const { addToast } = useToasts();
    const [isSaving, setIsSaving] = useState(false);
    const mode = entity.id ? "edit" : "add";

    const onValid = async (values: any) => {
        // values for disabled input does are not submitted
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/disabled
        const mergedValues = merge({}, entity, values);

        for (const p of getEntityProperties(schema)) {
            if (secretValueUnchanged(p)) {
                mergedValues[p.id] = undefined;
            }
        }

        try {
            setIsSaving(true);
            await saveEntity(type, mergedValues);
            addToast("Entity was successfully saved", {
                appearance: "success",
            });
            close(mergedValues);
        }
        catch (e: any) {
            const errorMessage = formatError("Saving failed", e);
            addToast(errorMessage, { appearance: "error" });
            setIsSaving(false);
        }
    };

    const onCancel = () => {
        close(false);
    };

    const renderInput = (property: PropertySchema, controllerProps: ControllerRenderProps) => {
        const disabled = property.readonly && mode === "edit";
        const placeholder = property.type === "association" ? `Select ${property.label}` : property.label;
        const inputProps = {
            ...controllerProps,
            disabled,
            placeholder
        }

        const { onChange, value, ...rest } = inputProps;
        switch (property.type) {
            case "association":
                return <Select entityType={property.associationEntityType} entityProperty={property.associationKey}  {...inputProps} />

            case "boolean":
                return <Checkbox checked={value ?? false} onChange={(_, d) => onChange(d.checked)} {...rest} />

            case "date":
                return <DatePicker {...inputProps} />

            case "enum":
                return <Select options={property.options} {...inputProps} />

            case "number":
                return <Input value={value ?? ""} onChange={onChange} type="number" {...rest} />

            default:
                return <Input value={value ?? ""} onChange={onChange} autoComplete="off" {...rest} />
        }
    }

    const getDefaultValue = (property: PropertySchema) => {
        switch (property.type) {
            case "boolean":
                return false;

            case "association":
            case "enum":
            case "date":
                return null;

            default:
                return ""
        }
    }

    const secretValueUnchanged = (property: PropertySchema) => {
        return mode === "edit" && property.type === "secret" && !dirtyFields[property.id];
    }

    const renderController = (property: PropertySchema) => {
        const skipValidation = secretValueUnchanged(property);

        const required = property.required && !skipValidation;;
        const shouldValidatePattern = property.pattern && !skipValidation;
        const pattern = shouldValidatePattern ?
            new RegExp(property.pattern!) :
            undefined;

        const rules = {
            required,
            pattern
        }

        const defaultValue = getDefaultValue(property);

        return (
            <Controller name={property.id}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field }) => renderInput(property, field)} />
        )
    }

    const renderError = (property: PropertySchema) => {
        const error = errors[property.id];
        if (error?.type === "required")
            return <Label pointing prompt color="red">{property.label} is required</Label>
        if (error?.type === "pattern")
            return <Label pointing prompt color="red">{property.label} must match format: ${property.pattern}</Label>
    };

    const transformFieldValue = (property: PropertySchema) => {
        const name = property.id;
        const value = getValues(name);

        if (property.type === "string") {
            const convertedValue = value?.trim();
            setValue(name, convertedValue);
        }

        if (property.type === "number") {
            const convertedValue = value !== null && value !== "" ? toNumber(value) : null;
            setValue(name, convertedValue);
        }
    }

    const onSubmit = (event) => {
        // manual transformation is needed because react-form-hooks does not support setValueAs for Controller 
        // https://github.com/react-hook-form/react-hook-form/issues/4673
        for (const p of getEntityProperties(schema)) {
            transformFieldValue(p);
        }

        const handler = handleSubmit(onValid);
        return handler(event);
    }

    const entityProperties = getEntityProperties(schema);
    return (
        <div className="cs-schema-field-form">
            <div className="section-header">
                <div className="label">
                    {mode === "edit" ? `${getDisplayName(type, entity)}` : `Add ${type}`}
                </div>
            </div>
            <Form onSubmit={onSubmit}>
                {entityProperties.map((p) => (
                    <Form.Field key={p.id} required={p.required} error={!!errors[p.id]}>
                        <label htmlFor="{p.id}">{p.label}</label>
                        {renderController(p)}
                        {renderError(p)}
                        {p.type === "secret" && mode === "add" && (
                            <Message>
                                Make sure to copy the value now. You won't be able to retrieve it after it's saved.
                            </Message>
                        )}
                    </Form.Field>
                ))}

                <div className="buttons-ctx">
                    <Button content="Submit" color="green" type="submit" disabled={isSaving} />
                    <Button content="Cancel" color="red" onClick={onCancel} />
                </div>
            </Form>
        </div >
    );
}

export default EntityForm;
