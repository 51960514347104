import { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Menu } from "semantic-ui-react";
import { aclTypesState } from "../../../../atoms/acl.atoms";
import './Schema.scss';
import SchemaTable from "./SchemaTable";

function Schema() {

    let { _, params: { schemaType } } = useRouteMatch();
    const [type, setType] = useState<any>(null);
    const types = useRecoilValue(aclTypesState);

    useEffect(() => {
        setType(types.find(p => p.id === schemaType));
    }, [schemaType, types, setType]);

    return (
        <div className="cs-schema-wrapper">
            <div className="section-title">
                <div className="label">ACL Schema Administration</div>
            </div>
            <div className="content-ctx">
                <Menu vertical size="large" className="list-ctx">
                    {
                        types.map(t => {
                            return <Menu.Item key={t.id} name={t.label} as={Link} to={t.id} active={t.id === type?.id} />
                        })
                    }
                </Menu>
                <div className="contents">
                    {type && <SchemaTable typeId={type.id}></SchemaTable>}
                </div>
            </div>
        </div>
    );
}

export default Schema;
