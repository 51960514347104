import { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Menu } from "semantic-ui-react";
import { aclTypesState } from "../../../../atoms/acl.atoms";

import "./Administer.scss";
import Entity from "./Entity";

function Administer() {
    let {
        params: { entityType },
    } = useRouteMatch();
    const [type, setType] = useState<any>(null);
    const types = useRecoilValue(aclTypesState);

    useEffect(() => {
        setType(types.find((p) => p.id === entityType));
    }, [entityType, types, setType]);

    return (
        <div className="cs-acl-wrapper">
            <div className="section-title">
                <div className="label">ACL Administration</div>
            </div>
            <div className="content-ctx">
                <Menu vertical size="large" className="list-ctx">
                    {
                        types.map(t => {
                            return <Menu.Item key={t.id} name={t.label} as={Link} to={t.id} active={t.id === type?.id} />
                        })
                    }
                </Menu>
                <div className="contents">
                    {type && (
                        <Entity typeId={type.id} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Administer;
