import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ToastProvider } from "react-toast-notifications";
import { useRecoilValue } from "recoil";
import authState from '../atoms/auth.atoms';

import './App.scss';
import SiteTracker from "./SiteTracker";
import Header from "./header/Header";
import KnowledgeCenter from "./knowledge_center/KnowledgeCenter";
import Login from "./Login";
import Manage from "./manage/Manage";
import Profile from "./Profile";
import Site from "./Site";
import Home from "./home/Home";

function App() {
    const auth = useRecoilValue(authState);

    if (auth) {
        return (
            <div className="app-ctx">
                <Router>
                    <SiteTracker>
                        <ToastProvider autoDismiss>
                            <Header></Header>
                            <div className="cs-root-content-ctx">
                                <Switch>
                                    <Route exact path="/sites/*" component={Site} />
                                    <Route path="/manage" component={Manage} />
                                    <Route exact path="/my/profile" component={Profile} />
                                    <Route exact path="/knowledge_center" component={KnowledgeCenter} />
                                    <Route exact path="/" component={Home} /> 
                                    <Route path="*" component={Home} /> {/* This will catch any unmatched paths */}
                                </Switch>
                            </div>
                        </ToastProvider>
                    </SiteTracker>
                </Router>
            </div>
        );
    } else {
        return <Login />;
    }

}

export default App;
