const parseEnvName = () => {
    const hostname = window.location.hostname;

    if (hostname === 'cloud.creditsights.com') {
        return '';
    } else if (hostname.match(/cloud-(dev|qa|stg)\.staging\.creditsights\.com/)) {
        const subdomain = hostname.split('.')[0];
        const env = subdomain.split('-')[1].toUpperCase();
        return env === 'STG' ? 'STAGE' : env;
    } else if (hostname.match(/cloud\.(dev|qa|staging)\.creditsights\.io/)) {
        const subdomain = hostname.split('.')[1];
        return subdomain.toUpperCase() === 'STAGING' ? 'STAGE' : subdomain.toUpperCase();
    } else {
        return 'DEV';
    }
};

export const ENV_NAME = parseEnvName();

export const parseUrl = () => {
    const l = window.location;
    return `${l.protocol}//${l.host}`;
};

export const fetchPath = () => {
    return window.location.pathname;
}

export const fetchSiteInfofromPath = () => {
    const path = fetchPath();
    const splits = path.split('/').filter(p => p);

    if (splits.length > 1 && splits[0] === 'sites') {
        return [splits[1], splits.slice(2).join('/')];
    } else {
        return [null, null];
    }
}

export const resolveSiteUrl = (site, loadTime, location) => {
    let pathname = location.pathname;

    if (!pathname.endsWith('/')) {
        pathname += '/';
    }

    if (pathname.startsWith(`/sites/${site.app_id}/`)) {
        const partialUrl = pathname.replace(`/sites/${site.app_id}/`, '');
        let url = `/web/${site.app_id}/${partialUrl}`;

        if (!url.endsWith('/')) {
            url += '/';
        }

        url += `?d=${loadTime}`;
        return url;
    } else {
        return `/web/${site.app_id}/?d=${loadTime}`;
    }

};
