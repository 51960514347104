import { useState } from "react"
import { Button, Icon, Modal } from "semantic-ui-react";


export type ActionIconProps = {
    message: string,
    icon: "trash",
    disabled?: boolean | undefined
    onConfirm: Function,
};

export function ActionIcon(props: ActionIconProps) {
    const { icon, message, disabled, onConfirm: onAction } = props;
    const [open, setOpen] = useState(false);

    const handleConfirm = () => {
        onAction();
        setOpen(false);
    }

    return <Modal open={open} size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={<Icon name={icon} disabled={disabled} />}
    >
        <Modal.Header>{message}</Modal.Header>
        <Modal.Actions>
            <Button color="green" onClick={handleConfirm}>Confirm</Button>
            <Button color="red" onClick={() => setOpen(false)}>Cancel</Button>
        </Modal.Actions>
    </Modal>
}