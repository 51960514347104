import {useRecoilValue} from "recoil";
import authState from "../atoms/auth.atoms";

import './Profile.scss';

function Profile() {
    const user = useRecoilValue(authState);

    return (
        <div className="ui container profile-wrapper">
            <div className="section-title"><div className="label">My Profile</div></div>
            <form className="ui form">
                <div className="field">
                    <div className="two fields">
                        <div className="field">
                            <label>First Name</label>
                            <input type="text" name="firstName" placeholder="First Name" defaultValue={user.firstName}/>
                        </div>
                        <div className="field">
                            <label>Last Name</label>
                            <input type="text" name="lastName" placeholder="Last Name" defaultValue={user.lastName}/>
                        </div>
                    </div>
                    <div className="four fields">
                        <div className="field">
                            <label>Username</label>
                            <input type="text" name="username" placeholder="Street Address" defaultValue={user.username}/>
                        </div>
                        <div className="field">
                            <label>Email</label>
                            <input type="text" name="emailAddress" placeholder="Street Address" defaultValue={user.emailAddress}/>
                        </div>
                        <div className="field">
                            <label>Phone</label>
                            <input type="text" name="phoneNumber" placeholder="Apt #" defaultValue={user.phoneNumber}/>
                        </div>
                        <div className="field">
                            <label>Role</label>
                            <input type="text" name="role" placeholder="Role" defaultValue={user.role}/>
                        </div>
                    </div>
                    <div className="fields">
                        <div className="twelve wide field">
                            <label>Street Address</label>
                            <input type="text" name="address1" placeholder="Street Address" defaultValue={user.address1}/>
                        </div>
                        <div className="four wide field">
                            <label>Apt #</label>
                            <input type="text" name="address2" placeholder="Apt #" defaultValue={user.address2}/>
                        </div>
                    </div>
                    <div className="three fields">
                        <div className="field">
                            <label>City</label>
                            <input type="text" name="city" placeholder="City" defaultValue={user.city}/>
                        </div>
                        <div className="field">
                            <label>State</label>
                            <input type="text" name="state" placeholder="State" defaultValue={user.state}/>
                        </div>
                        <div className="field">
                            <label>Zipcode</label>
                            <input type="text" name="zip" placeholder="Zip" defaultValue={user.zip}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Profile;
