import { randexp } from "randexp";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useRecoilValue } from "recoil";
import { Sidebar } from "semantic-ui-react";
import { deleteEntity } from "../../../../apis/acl.api";
import { canEditEntityQuery } from "../../../../atoms/permissions.atoms";
import { getEntityProperties } from "../../../../services/acl.service";
import { useEntityState, useSchemaState } from "../../../../states/acl.state";
import { formatError } from "../../../../utils/error.utils";
import { Pagination } from "../../../common/Pagination";
import Spinner from "../../../common/Spinner";
import "./Entity.scss";
import EntityActions from "./EntityActions";
import EntityForm from "./EntityForm";
import EntityTable from "./EntityTable";
import { EntityAssociations } from "./EntityAssociations";
import { EntityAccess } from "./EntityAccess";
import EntityFilter from "./EntityFilter";

function Entity({ typeId }) {
    const { addToast } = useToasts();
    const [schema]: any = useSchemaState(typeId);

    const [entityProperties, setEntityProperties]: any = useState<any[]>([]);
    const [entities, pageIndex, sortField, sortDesc, filter, refresh] = useEntityState(typeId);
    const [selectedEntities, setSelectedEntities] = useState({});
    const [activeEntity, setActiveEntity]: any = useState();
    const [openSidebar, setOpenSidebar] = useState<"edit" | "info" | "associations">();

    useEffect(() => {
        const entityProps = getEntityProperties(schema);
        setEntityProperties(entityProps);
        setSelectedEntities({});
    }, [schema]);

    const handleFilterChange = (filter) => {
        refresh({
            page: 0,
            filter
        });
    };

    const selectEntity = (entity: any, selected: boolean) => {
        setSelectedEntities({
            ...selectedEntities,
            [entity.id]: selected,
        });
    };

    const openAssociationsForm = (entity) => {
        setActiveEntity(entity);
        setOpenSidebar("associations");
    };

    const showEditForm = (entity?) => {
        if (!entity) {
            entity = getDefaultData();
        }

        setActiveEntity(entity);
        setOpenSidebar("edit");
    };

    const showEntityAccess = (entity?) => {
        setActiveEntity(entity)
        setOpenSidebar("info");
    };

    const getDefaultData = () => {
        // Do not confuse schema id (key) with property id (key)
        const keySchema = schema?.find((x) => x.id === "key");
        if (typeId === "key" && keySchema.pattern) {
            return {
                // TODO: use proper api key generation mechanism
                // This is not safe..
                key: randexp(keySchema.pattern),
            };
        }

        return {};
    };

    const closeSidebar = () => {
        setOpenSidebar(undefined);
    }

    const closeEditForm = (values) => {
        if (values)
            refresh();

        closeSidebar();
    }

    const handleDelete = async (entity: any) => {
        try {
            await deleteEntity(typeId, entity.id);
            addToast("Record was successfully deleted", {
                appearance: "success",
            });
        } catch (e) {
            const errorMessage = formatError("Deleting entity failed", e);
            addToast(errorMessage, { appearance: "error" });
        }

        refresh();
    };


    const renderAssociationsInfo = (canEdit) => {
        return <EntityAssociations entity={activeEntity} entityType={typeId} readOnly={!canEdit} />
    };

    const renderEditForm = () => {
        return <EntityForm entity={activeEntity} schema={schema} type={typeId} close={closeEditForm} />
    };

    const renderInfo = () => {
        return <EntityAccess entityType={typeId} entity={activeEntity} />;
    };

    const renderSidebar = (canEdit) => {
        if (!activeEntity) return;

        switch (openSidebar) {
            case "associations":
                return renderAssociationsInfo(canEdit);
            case "edit":
                return renderEditForm();
            case "info":
                return renderInfo();
        }
    }

    const canEdit = useRecoilValue(canEditEntityQuery(typeId));
    const canShowAccess = ["user", "group", "key"].includes(typeId);

    if (schema && entities) {
        return (
            <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={!!openSidebar}>
                    <div className="cs-entity-ctx">
                        <div className="section-header">
                            <EntityFilter
                                entityType={typeId}
                                entityProperties={entityProperties}
                                onSearch={handleFilterChange}
                            />

                            <div className="actions">
                                <EntityActions
                                    entityType={typeId}
                                    selectedEntities={selectedEntities}
                                    canEdit={canEdit}
                                    sortField={sortField}
                                    sortDesc={sortDesc}
                                    filter={filter}
                                    onEntityAdd={() => showEditForm(null)}
                                    onSearch={handleFilterChange}
                                    onImportCompleted={refresh}
                                    onImportFailed={refresh}
                                />
                            </div>
                        </div>
                        <div className="table-ctx">
                            <EntityTable
                                entityType={typeId}
                                entities={entities}
                                selectedEntities={selectedEntities}
                                entityProperties={entityProperties}
                                sortField={sortField}
                                sortDesc={sortDesc}
                                canAdminister={canEdit}
                                canShowAccess={canShowAccess}
                                onEntityDelete={handleDelete}
                                onEntityEdit={showEditForm}
                                onEntitySelect={selectEntity}
                                onEntityShowAccess={showEntityAccess}
                                onEntityShowAssociations={openAssociationsForm}
                                onSortChange={(sort, desc) => refresh({ sort, sortDesc: desc })}
                            />
                        </div>
                        <div className="pagination-ctx">
                            <Pagination current={pageIndex} onNextClick={() => refresh({ page: pageIndex + 1 })} onPrevClick={() => refresh({ page: pageIndex - 1 })} />
                        </div>
                    </div>

                </Sidebar.Pusher>
                <Sidebar visible={!!openSidebar} animation='overlay' icon='labeled' direction="right" onHide={closeSidebar} width="very wide" content={renderSidebar(canEdit)} />
            </Sidebar.Pushable>
        );
    } else {
        return <Spinner color="#2185d0" size="75px" />;
    }
}

export default Entity;
