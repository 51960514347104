import React from "react";
import {Route, Switch, useRouteMatch} from 'react-router-dom'
import {useRecoilValue} from "recoil";
import {appsState} from "../../atoms/app.atoms";
import Acl from "./acls/Acl";
import Apis from "./apis/Apis";
import Sites from "./sites/Sites";

function Manage() {
    let {path} = useRouteMatch();
    const apps = useRecoilValue(appsState);

    return (
        <Switch>
            <Route exact path={`${path}/sites`}>
                <Sites sites={apps}/>
            </Route>
            <Route exact path={`${path}/apis`}>
                <Apis sites={apps}/>
            </Route>
            <Route path={`${path}/acls`}>
                <Acl/>
            </Route>
        </Switch>
    );

}

export default Manage;
