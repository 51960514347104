import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Sidebar } from "semantic-ui-react";
import { saveSchema } from "../../../../apis/acl.api";
import { useSchemaState } from "../../../../states/acl.state";
import { formatError } from "../../../../utils/error.utils";
import { ActionIcon } from "../../../common/ActionIcon";
import Spinner from "../../../common/Spinner";
import SchemaFieldForm from "./SchemaFieldForm";
import './SchemaTable.scss';

function SchemaTable({ typeId }) {
    const type = `${typeId}`;
    const { addToast } = useToasts();
    const [field, setField] = useState<any>(null);
    const [processing, setProcessing] = useState(false);
    const [schema, setSchema]: any = useSchemaState(type);

    const save = async () => {
        setProcessing(true);
        try {
            await saveSchema(type, schema);
            addToast('Schema was successfully saved', {
                appearance: 'success'
            });
        }
        catch (e) {
            const errorMessage = formatError("Saving schema failed", e);
            addToast(errorMessage, { appearance: "error" });
        }

        setProcessing(false);
    };

    const removeItem = (index) => {
        schema.splice(index, 1);
        setSchema([...schema]);
    };

    const showForm = (field?) => {
        if (field) {
            setField(field);
        } else {
            setField({
                id: null,
                label: null,
                required: false,
                type: 'string',
                system: false,
                readonly: false
            })
        }
    };

    const updateField = (originalField, updatedField) => {
        const newSchema = [...schema];
        const fieldIndex = schema.findIndex(item => item.id === updatedField.id);

        if (!originalField.id && fieldIndex !== -1) {
            addToast(`Field with id: ${updatedField.id} already exists`, {
                appearance: "error"
            });
        }
        else {     
            if (fieldIndex !== -1) {
                newSchema.splice(fieldIndex, 1, updatedField);
            }
            else {
                newSchema.push(updatedField);
            }

            setSchema(newSchema);
        }
        closeForm();
    }

    const closeForm = () => {
        setField(null);
    };

    const showEditForm = !!field;
    if (schema && !processing) {
        return (
            <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={showEditForm}>
                    <div className="cs-schema-table">
                        <div className="section-header">
                            <div className="actions">
                                <div className="ui blue button" onClick={() => showForm()}>Add Field</div>
                                <div className="ui green button" onClick={save}>Save</div>
                            </div>
                        </div>
                        <div className="table-ctx">
                            <table className="ui selectable celled table">
                                <thead>
                                    <tr>
                                        <th style={{ width: 70 }}></th>
                                        <th>id</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Required</th>
                                        <th>Read-only</th>
                                        <th>Unique</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {schema.map((item, index) => (
                                        <tr key={item.id}>
                                            <td className="center aligned">
                                                <i className="ui icon edit outline" onClick={() => showForm({ ...item })}></i>
                                                <ActionIcon message={`Are you sure you want to delete "${item.label}" field?`} icon="trash" disabled={item.system} onConfirm={() => removeItem(index)} />
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.label}</td>
                                            <td>{item.type.toUpperCase()}</td>
                                            <td>{item.required ? 'Yes' : 'No'}</td>
                                            <td>{item.readonly ? 'Yes' : 'No'}</td>
                                            <td>{item.unique ? 'Yes' : 'No'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Sidebar.Pusher>
                <Sidebar visible={showEditForm} animation='overlay' icon='labeled' direction="right" onHide={() => closeForm()} width="very wide" >
                    {showEditForm && <SchemaFieldForm onCancel={closeForm} onSubmit={newField => updateField(field, newField)} field={field} />}
                </Sidebar>
            </Sidebar.Pushable>
        );
    } else {
        return <Spinner color="#2185d0" size="75px" />;
    }

}

export default SchemaTable;
