import api from "../apis/platform.api";
import {get} from 'lodash';
/**
 * Module with functions responsible for interactions with user api.
 **/

/**
 * Fetch list of applications current user has permission to.
 * @returns list of applications
 */
export const fetchApplications = async () => {
    try {
        return (await api.get("/user/applications")).data;
    } catch (e) {
        console.error(e);

        if (get(e, 'response.status') === 401) {
            /*
            * Fix for a race condition when cookie expires but token is still available.
            * Observed after live release April 1 - 2022.
            * Should not happen after all users re-login.
            * */
            localStorage.removeItem('cs-platform-token');
            window.location.reload();
        }

        return [];
    }
};

/**
 * Fetch list of application specific permissions granted to current user
 * @param app_id app_id of the application
 * @returns list of applications
 */
export const fetchPermissions = async (app_id: string) => {
    return (await api.get(`/user/permissions?app_id=${app_id}`)).data;
};
