/**
 * Module with non-ui functions related to ACL.
 **/

import * as xlsx from "xlsx";
import _, { sortBy } from "lodash";

import { fetchEntities, exportAssociations, importEntities, importAssociations } from "../apis/acl.api";

import { readFile } from "../utils/file.utils";
import { getDisplayName } from "../utils/entity-type.utils";
import { PropertySchema } from "../types/acl.types";

/**
 * Exports entities of given type that are matching supplied query.
 * Entities are fetched form API, then transformed and exported as an excel file.
 *
 * @param entityType type of entities to export
 * @param query query to filter the list of entities to export
 */
export async function exportEntitiesToExcel(entityType: string, query: any) {
    const entities = await fetchEntities(entityType, query);

    const wb = xlsx.utils.book_new();
    const worksheet = xlsx.utils.json_to_sheet(entities);
    xlsx.utils.book_append_sheet(wb, worksheet);

    xlsx.writeFile(wb, `${entityType}s-export.xlsx`);
}

/**
 * Exports associations between entities of type 'entityType' and 'associatedEntityType'.
 * Associations are fetched form API, then transformed to a matrix and exported as an excel file.
 *
 * @param entityType type of entities for which associations are exported
 * @param associatedEntityType type of associated entities
 * @param entityIds ids of entities for which associations are exported
 */
export async function exportAssociationsToExcel(entityType: string, associatedEntityType: string, entityIds: any[]) {
    const entities = await getEntities(entityType, entityIds);
    const associations = await exportAssociations(entityType, associatedEntityType, {
        ids: entityIds,
    });

    const associatedEntitiesIds = associations.flatMap((x) => x.associations).map((a) => a[associatedEntityType]);

    const associatedEntities = await getEntities(associatedEntityType, associatedEntitiesIds);

    const associationMapItems = associations
        .map((x) =>
            x.associations.map((a) => ({
                entityId: x.entityId,
                associatedEntityId: a[associatedEntityType],
                association: a,
            }))
        )
        .flat();

    const associationsMap = _.groupBy(associationMapItems, "associatedEntityId");

    const schema = getAssociationSchema(entityType, associatedEntityType);
    const allowDeny = schema.some((p) => p.id === "allowDeny");

    const sortedEntities = sortBy(entities, getDefaultOrder(entityType));
    const sortedAssociatedEntities = sortBy(associatedEntities, getDefaultOrder(associatedEntityType));

    const data = [
        ["", "", ...sortedEntities.map((x) => x.id)],
        ["", "", ...sortedEntities.map((x) => getDisplayName(entityType, x))],
    ];
    for (const associatedEntity of sortedAssociatedEntities) {
        const associationsGroup = associationsMap[associatedEntity.id];
        const associationsLookup = _.keyBy(associationsGroup, "entityId");

        data.push([
            associatedEntity.id,
            getDisplayName(associatedEntityType, associatedEntity),
            ...sortedEntities.map((e) => {
                const association = associationsLookup[e.id]?.association;
                if (!association) return "";

                if (allowDeny) return association["deny"] ? "Deny" : "Allow";

                return true;
            }),
        ]);
    }

    const wb = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(wb, worksheet);

    xlsx.writeFile(wb, `${entityType}s-${associatedEntityType}s-export.xlsx`);
}

/**
 * Import entities of given from excel file.
 *
 * @param entityType type of entities to import
 * @param file excel file containing entity data to import
 */
export async function importEntitiesFromExcel(entityType: string, file: File) {
    const arrayBuffer = (await readFile(file)) as ArrayBuffer;
    const wb = xlsx.read(new Uint8Array(arrayBuffer), { type: "array" });

    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const data: any[] = xlsx.utils.sheet_to_json(worksheet);

    await importEntities(entityType, data);
}

/**
 * Imports associations between entities of type 'entityType' and 'associatedEntityType' from an excel file.
 *
 * @param entityType type of entities for which associations are imported
 * @param associatedEntityType type of associated entities
 * @param file excel file containing associations data to import
 */
export async function importAssociationsFromExcel(entityType: string, associatedEntityType: string, file: File) {
    const arrayBuffer = (await readFile(file)) as ArrayBuffer;
    const wb = xlsx.read(new Uint8Array(arrayBuffer), { type: "array" });

    const worksheet = wb.Sheets[wb.SheetNames[0]];

    const data: string[][] = xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
    });

    const columnStart = 2; // skip row Id, Name columns
    const rowsStart = 2; //skip Id, Name rows
    const dtos: any[] = [];

    const schema = getAssociationSchema(entityType, associatedEntityType);
    const allowDeny = schema.some((p) => p.id === "allowDeny");

    for (let c = columnStart; c < data[0].length; c++) {
        const entityId = data[0][c];
        const associations: any[] = [];

        for (let r = rowsStart; r < data.length; r++) {
            const associatedEntityId = data[r][0];
            const associationIndicator = data[r][c];

            if (!associationIndicator) continue;

            let associationProps = {};
            if (allowDeny) {
                const indicatorValue = associationIndicator?.trim().toLowerCase();
                if (indicatorValue === "allow") associationProps["deny"] = false;
                if (indicatorValue === "deny") associationProps["deny"] = true;
            }
            const association = {
                [associatedEntityType]: associatedEntityId,
                ...associationProps,
            };

            associations.push(association);
        }

        dtos.push({
            entityId,
            associations,
        });
    }

    await importAssociations(entityType, associatedEntityType, dtos);
}

/**
 * Gets entities of give type and ids from and api
 *
 * @param entityType type of an entity
 * @param entityIds ids of entities to fetch
 * @returns list of entities
 */
export async function getEntities(entityType: string, entityIds: string[]): Promise<any[]> {
    if (entityIds?.length > 0) {
        const chunks = _.chunk(entityIds, 100).map((ids) => fetchEntities(entityType, { ids }));
        return (await Promise.all(chunks)).flat();
    }

    return [];
}

export function getAssociationSchemas(entityType: string): any {
    const schemas = {
        application: [],
        group: {
            application: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            group: [],
            key: [],
            package: [
                { id: "allowDeny", label: "Allow/Deny", type: "boolean" },
                { id: "startDate", label: "Start", type: "date" },
                { id: "endDate", label: "End", type: "date" },
            ],
            permission: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            user: [],
        },
        key: {
            application: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            group: [],
            package: [
                { id: "allowDeny", label: "Allow/Deny", type: "boolean" },
                { id: "startDate", label: "Start", type: "date" },
                { id: "endDate", label: "End", type: "date" },
            ],
            permission: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
        },
        package: {
            application: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            package: [],
            permission: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
        },
        permission: {
            group: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            package: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
        },
        user: {
            application: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
            group: [],
            package: [
                { id: "allowDeny", label: "Allow/Deny", type: "boolean" },
                { id: "startDate", label: "Start", type: "date" },
                { id: "endDate", label: "End", type: "date" },
            ],
            permission: [{ id: "allowDeny", label: "Allow/Deny", type: "boolean" }],
        },
    };

    return schemas[entityType];
}

export function getAssociationSchema(entityType: string, associatedEntityType: string): any[] {
    return getAssociationSchemas(entityType)[associatedEntityType] ?? [];
}

export function getAssociatedTypes(entityType: string): string[] {
    const schemas = getAssociationSchemas(entityType);
    return schemas ? Object.keys(schemas) : [];
}

/**
 * Returns properties for a given schema, but exclude meta properties (prefixed with $).
 * @param schema entity schema
 * @returns
 */
export function getEntityProperties(schema: any): PropertySchema[] {
    return schema?.filter((x) => x.id !== "id" && !x.id.startsWith("$")) || [];
}

/**
 * Returns default sort property for given type
 * @param type entity type
 * @returns default sort property id
 */
export function getDefaultOrder(type: string) {
    return type === "user" ? "username" : "name";
}
