import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Input, InputProps } from "semantic-ui-react";
import { convertDefaultTZToUtc, convertUtcToDefaultTZ, sameDay, setDefaultTime, DATE_FORMAT } from "../../utils/date-utils";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

type DatePickerProps = {
    name?: string | undefined;
    value?: string | undefined;
    placeholder?: string | undefined;
    disabled?: boolean | undefined;
    onChange?: (value: string | null) => void;
    onBlur?: () => void;
    size?: InputProps['size'];
}

export const DatePicker = forwardRef((props: DatePickerProps, ref: any) => {
    const { name, value, onChange, disabled, size, onBlur } = props;
    const dateInTZ = convertUtcToDefaultTZ(value);

    const handleOnChange = (newDateInTZ: Date) => {
        const shouldDefaultTime = !sameDay(dateInTZ, newDateInTZ);
        const newDateInTZWithTimeFixed = shouldDefaultTime ? setDefaultTime(newDateInTZ, name) : newDateInTZ;

        const newUtcDate = newDateInTZ && convertDefaultTZToUtc(newDateInTZWithTimeFixed);
        const newValue = newUtcDate ? newUtcDate.toISOString() : null;
        onChange && onChange(newValue);
    };

    return <ReactDatePicker
        selected={dateInTZ}
        onChange={handleOnChange}
        placeholderText={props.placeholder}
        showTimeInput
        disabled={disabled}
        isClearable={!disabled}
        popperPlacement="top"
        dateFormat={DATE_FORMAT}
        customInput={<Input disabled={disabled} name={name} size={size} onBlur={onBlur} ref={ref} icon="calendar" fluid iconPosition="left" />}
    />
});
