import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Button } from "semantic-ui-react";
import { clearCache } from "../../../../apis/acl.api";
import { formatError } from "../../../../utils/error.utils";
import "./Settings.scss"

function Settings() {
    const [isClearingCache, setIsClearingCache] = useState(false);
    const { addToast } = useToasts();

    const onClearCache = async () => {
        setIsClearingCache(true);
        try {
            await clearCache();
            addToast("ACL cache cleared", {
                appearance: "success",
            });
        }
        catch (e) {
            const errorMessage = formatError("Clearing ACL cache failed", e);
            addToast(errorMessage, { appearance: "error" });
        }
        finally {
            setIsClearingCache(false);
        }
    }

    return (
        <div className="cs-acl-settings-wrapper">
            <div className="section-title">
                <div className="label">ACL Settings</div>
            </div>
            <div className="content-ctx">
                <div className="contents">
                    <Button className="ui button green" disabled={isClearingCache} onClick={onClearCache}>Clear ACL cache</Button>
                </div>
            </div>
        </div>
    );
}

export default Settings;
