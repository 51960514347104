import { getAssociatedTypes } from "../../../../services/acl.service";
import { getDisplayName } from "../../../../utils/entity-type.utils";
import { EntityAssociationsList } from "./EntityAssociationsList";

export function EntityAssociations({ entity, entityType, readOnly }) {
    const isSystemGroup = entityType === "group" && entity.$system;

    // system groups can't have members added/removed
    const associatedTypes = !isSystemGroup ? getAssociatedTypes(entityType) : getAssociatedTypes(entityType).filter((t) => t !== "user" && t !== "key");

    const extraProps = {
        application: {
            lookup: "name",
            includeSystemEntities: true,
        },
        group: {
            lookup: "name",
        },
        key: {
            lookup: "name",
        },
        package: {
            lookup: "name",
        },
        permission: {
            lookup: "name",
            includeSystemEntities: true,
        },
        user: {
            lookup: "username",
            descriptionGenerator: (entity) => getDisplayName("user", entity),
        },
    };

    return (
        <div className="cs-entity-associations">
            <div className="section-header">
                <div className="label">{getDisplayName(entityType, entity)}</div>
            </div>
            {associatedTypes.map((t) => {
                return <EntityAssociationsList key={t} record={entity} entityType={entityType} associationType={t} {...extraProps[t]} readOnly={readOnly} />;
            })}
        </div>
    );
}

export default EntityAssociations;
