export function getLabel(entityType: string, capitalize: boolean = true) {
    const plural = entityType + "s";
    if (!capitalize) return plural;

    //capitalize first letter
    return plural.replace(/^\w/, (c) => c.toUpperCase());
}

export function getDisplayName(entityType: string, entity: any) {
    if (entityType === "user") {
        return `${entity.firstName} ${entity.lastName}`;
    }

    return entity.name;
}