import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {  Dropdown, Icon } from "semantic-ui-react";
import { selectedAppLoadTimeState, uiAppsState } from "../../atoms/app.atoms";

export default function AppsDropdown() {
    const apps = useRecoilValue(uiAppsState);
    const setLoadTime = useSetRecoilState(selectedAppLoadTimeState);

    const forceRefresh = () => {
        setTimeout(() => setLoadTime(Date.now()), 100);
    };

    const renderLink = (app) => {
        return <Dropdown.Item key={app.app_id} as={Link} onClick={forceRefresh} text={app.name} to={`/sites/${app.app_id}/`} />
    }

    const trigger = (
        <span>
            <Icon name="th"/> Apps <Icon name="dropdown" />
        </span>
    );

    return <Dropdown item trigger={trigger} icon={null} className="cs-menu-dropdown">
        <Dropdown.Menu>
            <Dropdown.Header>Available apps</Dropdown.Header>
            {apps.map(renderLink)}
            <Dropdown.Item as={Link} text="Knowledge Center" to="/knowledge_center" />
        </Dropdown.Menu>
    </Dropdown>
}