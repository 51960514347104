export function formatError(errorMessage: string, e: any) {
    // axios error
    if (e.response) {
        const responseDetails = e.response.data?.details;
        const responseMessage = e.response.data?.message;

        if (!responseMessage) return errorMessage;
        if (!responseDetails) return `${errorMessage}: ${responseMessage}`;

        return `${errorMessage}: ${responseMessage}. Details: ${responseDetails}`;
    }

    return `${errorMessage}: ${e.message}`;
}
