import { chunk } from "lodash";
import api from "./platform.api";
/**
 * Module with functions responsible for interactions with ACL api.
 **/


export const clearCache = async () => {
    await api.post(`acl/cache/clear`);
};

export const saveSchema = async (type, schema) => {
    await api.post(`acl/schema/${type}`, schema);
    return fetchSchema(type);
};

export const fetchSchema = async (type) => {
    const resp = await api.get(`acl/schema/${type}`);
    return resp.data;
};

export const fetchEntities = async (type, query): Promise<any[]> => {
    const resp = await api.get(`acl/entity/${type}s`, { params: query });
    return resp.data;
};

export const saveEntity = async (type, entity) => {
    await api.post(`acl/entity/${type}s`, entity);
};

/**
 * Calls the API to fetch associations between entities of `entityType` and `associatedEntityType` filtered by a `query`.
 * @param entityType type of an for which associations are exported
 * @param associatedEntityType type of an associated entities
 * @param query query limiting the entities for which associations are exported
 * @returns list of associations grouped by an entitiy id
 */
export const exportAssociations = async (
    entityType: string,
    associatedEntityType: string,
    query: {
        ids: string[];
    }
): Promise<any[]> => {
    const response = await api.get(`acl/entity/${entityType}s/associations/${associatedEntityType}`, {
        params: query,
    });

    return response.data;
};

export const importEntities = async (entityType: string, data: any) => {
    const chunks = chunk(data, 1000);
    for (const chunk of chunks) {
        await api.post(`acl/entity/${entityType}s/import`, chunk);
    }
};

/**
 * Calls the API to save associations between entities of `entityType` and `associatedEntityType`.
 * @param entityType type of an entities for which assoiciations are imported
 * @param associatedEntityType type of an associated entities
 * @param data list of associations grouped by an entitiy id
 */
export const importAssociations = async (entityType: string, associatedEntityType: string, data: any) => {
    await api.post(`acl/entity/${entityType}s/associations/${associatedEntityType}`, data);
};

export const fetchEntityAssociation = async (entityType, entityId, associationType): Promise<any[]> => {
    const url = entityType === associationType ? `acl/entity/${entityType}s/${entityId}/child-${associationType}s` : `acl/entity/${entityType}s/${entityId}/${associationType}s`;

    const resp = await api.get(url);
    return resp.data;
};

export const addEntityAssociation = async (entityType, entityId, associationType, associations) => {
    const url = entityType === associationType ? `acl/entity/${entityType}s/${entityId}/child-${associationType}s` : `acl/entity/${entityType}s/${entityId}/${associationType}s`;

    await api.post(url, associations);
};

export const deleteEntity = async (type, id) => {
    await api.delete(`acl/entity/${type}s/${id}`);
};

export const fetchEffective = async (entityType, entityId: string, grantType: "applications" | "permissions", asOf?: string | undefined) => {
    return await api.get<any[]>(`acl/entity/${entityType}s/${entityId}/effective-${grantType}`, {
        params: {
            asOf,
        },
    });
};
