import {Popup} from "semantic-ui-react";

function KnowledgeCenterGrid({videos, adminUser, moduleMap, parseVideoUrl, editVideo, deleteVideo}) {
    if (videos?.length === 0) {
        return <div className="empty-message">No resources found!</div>
    } else {

        const style = {
            width: '450px'
        }

        return <div className="grid-ctx">
            {videos.map(video => (
                <Popup
                    key={video.id}
                    content={video.description}
                    style={style}
                    position='top left'
                    flowing
                    inverted
                    trigger={
                        <a key={video.id} className="video-ctx" href={parseVideoUrl(video.url)} target="_blank">
                            <div className="thumbnail">
                                <a className={`ui ${moduleMap[video.module]} ribbon label`}>{video.module}</a>
                                {adminUser && <div className="thumbnail-button">
                                    <button onClick={(event) => editVideo(event, video)}><i className="edit outline icon"></i></button>
                                    <button onClick={(event) => deleteVideo(event, video)}><i className="trash icon"></i></button>
                                </div>}
                                <img src={video.thumbnail}/>
                            </div>
                        </a>
                    }
                />
            ))}
        </div>
    }
}

export default KnowledgeCenterGrid;
