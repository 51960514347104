import classNames from "classnames";
import {useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import api from "../../apis/platform.api";
import KnowledgeForm from "../../types/knowledge.types";
import {sleep} from "../../utils/time.utils";

function KnowledgeCenterForm({record, filterOptions, hideForm}) {
    const {register, handleSubmit, formState: {errors}} = useForm<KnowledgeForm>({defaultValues: record});
    const [thumbnail, setThumbnail] = useState(record?.thumbnail);
    const [processing, setProcessing] = useState(false);

    const renderErrorClass = (field: any) => {
        return classNames({
            field: true,
            error: field != null
        });
    };

    const onSubmit = async (values: any) => {
        if (thumbnail) {
            setProcessing(true);
            await api.post(`store/knowledge-center/videos:${values.key}`, {
                config: {
                    key: values.key,
                    title: values.title,
                    url: values.url,
                    description: values.description,
                    module: values.module,
                    order: Date.now()
                },
                payload: thumbnail
            });

            await sleep(0.5);
            hideForm();
            setProcessing(false);
        } else {
            alert('Thumbnail is required');
        }
    };

    const handleDrop = (acceptedFiles) => {
        if (acceptedFiles?.length > 0) {
            const file = acceptedFiles[0];

            if (file.type === 'image/png') {
                const reader = new FileReader();
                reader.onload = () => {
                    const result = reader.result;
                    setThumbnail(result);
                }

                reader.readAsDataURL(file);
            } else {
                alert('Only PNG files are acceptable')
            }
        }
    };

    const removeThumbnail = (event) => {
        event.preventDefault();
        setThumbnail(null);
    };

    const renderThumbnail = () => {
        if (thumbnail) {
            return (
                <div className="thumbnail">
                    <button className="thumbnail-button" onClick={removeThumbnail}><i className="window close outline icon"></i></button>
                    <img src={thumbnail}/>
                </div>
            )
        } else {
            return (
                <Dropzone onDrop={handleDrop}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className="drop-zone">
                            <input {...getInputProps()} />
                            <div>Drag 'n' drop thumbnail here or <a className="browse-link">browse</a></div>
                        </div>
                    )}
                </Dropzone>
            );
        }
    }

    return (
        <div className="grid-ctx">
            <form className="ui form record-form" onSubmit={handleSubmit(onSubmit)}>
                <div className={renderErrorClass(errors.title)}>
                    <label>Title</label>
                    <input placeholder="Title" {...register("title", {required: true})}/>
                    {errors.title && <div className="ui pointing red basic label">Title is required</div>}
                </div>
                <div className={renderErrorClass(errors.url)}>
                    <label>Resource URL</label>
                    <input placeholder="Resource URL" {...register("url", {required: true})}/>
                    {errors.url && <div className="ui pointing red basic label">Resource URL is required</div>}
                </div>
                <div className={renderErrorClass(errors.module)}>
                    <label>Module</label>
                    <select {...register("module", {required: true})}>
                        {filterOptions.map(item => <option key={item.key} value={item.value}>{item.text}</option>)}
                    </select>
                    {errors.module && <div className="ui pointing red basic label">Module is required</div>}
                </div>
                <div className={renderErrorClass(errors.description)}>
                    <label>Description</label>
                    <textarea rows={5} placeholder="Enter description here" {...register("description", {required: true})}/>
                    {errors.description && <div className="ui pointing red basic label">Description is required</div>}
                </div>
                {renderThumbnail()}
                <div className="buttons-ctx">
                    <button className="ui positive button" type="submit" disabled={processing}>Submit</button>
                    <a className="ui red button" onClick={() => hideForm()}>Cancel</a>
                </div>
            </form>
        </div>
    );
}

export default KnowledgeCenterForm;
