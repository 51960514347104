import { useEffect, useState } from "react";

import { DatePicker } from "../../../common/DatePicker";
import { Loader, Message } from "semantic-ui-react";
import { fetchEffective } from "../../../../apis/acl.api";
import { sortBy } from "lodash";
import { getDisplayName, getLabel } from "../../../../utils/entity-type.utils";
import { EntityType } from "../../../../atoms/permissions.atoms";

import './EntityAccess.scss';

export type EntityAccessProps = {
    entityType: EntityType,
    entity: any
}

export function EntityAccess(props: EntityAccessProps) {
    const { entityType, entity } = props;

    const [asOf, setAsOf] = useState<string>();
    const [effectiveApplications, setEffectiveApplications] = useState<any[]>([]);
    const [effectivePermissions, setEffectivePermissions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await updateEffective();
        })()
    }, [entityType, entity])


    const updateEffective = async (date?) => {
        setIsLoading(true);
        const effectiveAppsResponse = await fetchEffective(entityType, entity.id, "applications", date);
        setEffectiveApplications(sortBy(effectiveAppsResponse.data, "name"));

        const effectivePermissionsResponse = await fetchEffective(entityType, entity.id, "permissions", date);
        setEffectivePermissions(sortBy(effectivePermissionsResponse.data, "name"));
        setIsLoading(false);
    };

    const handleDateChange = async (date: string | null) => {
        setAsOf(date ?? "");
        await updateEffective(date);
    }

    const renderList = (items: any[], grantType: EntityType) => {
        if (isLoading)
            return (
                <Message>
                    <Loader active inline='centered' size="small" />
                </Message>
            )

        if (items.length === 0)
            return <Message>{getLabel(entityType)} does not have any {getLabel(grantType, false)}</Message>

        return (
            <div className="list-ctx">
                {items.map(a => {
                    return <div className="item" key={a.id}>{getDisplayName(grantType, a)}</div>
                })}
            </div>
        );
    }

    return <div className="cs-entity-access">
        <div className="section-header">
            <div className="label">{getDisplayName(entityType, entity)}</div>
        </div>
        <div className="section-subheader">
            <div className="label">Applications</div>
            <DatePicker value={asOf} onChange={handleDateChange} placeholder="As of" size="mini" />
        </div>
        {
            renderList(effectiveApplications, "application")
        }

        <div className="section-subheader">
            <div className="label">Permissions</div>
        </div>
        {
            renderList(effectivePermissions, "permission")
        }
    </div >
}