/**
 * Module with helper functions related to interactions with a filesystem from a browser.
 **/

/**
 * Opens a file dialog for user to select a file
 * @returns selected file
 */
export const getUploadFile = (): Promise<File | undefined> => {
    return new Promise((resolve, reject) => {
        const input = document.createElement("input");
        input.type = "file";
        input.onchange = () => {
            const file = input.files?.[0];
            resolve(file);
        };

        input.click();
    });
};

export const readFile = (
    file: File
): Promise<string | ArrayBuffer | null | undefined> => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = (e) => {
            resolve(e.target?.result);
        };

        reader.readAsArrayBuffer(file);
    });
};

export const saveAs = (blob: Blob, name: string) => {
    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectURL;
    a.download = name;
    a.click();
    URL.revokeObjectURL(objectURL);
};
