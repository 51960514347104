import { useEffect, useState } from "react";

import "./Entity.scss";

export type EntityActionsProps = {
    entityType: string,
    entityProperties: any[],
    onSearch: (filter: any) => void,
}

function EntityFilter(props: EntityActionsProps) {
    const { entityProperties } = props;

    const [filterText, setFilterText] = useState("");
    const [filterField, setFilterField]: any = useState();

    useEffect(() => {
        setFilterField(entityProperties[0]?.id);
        setFilterText("");
    }, [entityProperties]);

    const handleSearch = () => {
        const { onSearch } = props;

        onSearch({
            key: filterField,
            operator: "contains",
            value: filterText
        })
    }

    if (entityProperties?.length > 0) {
        return (
            <div className="filter-ctx">
                <div className="ui action input small">
                    <input type="text" placeholder="Search..." value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                    <select className="ui compact selection dropdown" value={filterField} onChange={(e) => setFilterField(e.target.value)}>
                        {entityProperties.map((property) => (
                            <option key={property.id} value={property.id}>
                                {property.label}
                            </option>
                        ))}
                    </select>
                    <div className="ui button" onClick={handleSearch}>
                        Search
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

export default EntityFilter;
