import {orderBy} from "lodash";
import {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {Dropdown} from "semantic-ui-react";
import api from "../../apis/platform.api";
import authState from "../../atoms/auth.atoms";
import './KnowledgeCenter.scss';
import Spinner from "../common/Spinner";
import KnowledgeCenterForm from "./KnowledgeCenterForm";
import KnowledgeCenterGrid from "./KnowledgeCenterGrid";
import KnowledgeCenterList from "./KnowledgeCenterList";


function KnowledgeCenter() {
    const targetUrl = 'https://auth.easygenerator.com/api/auth/oauth/creditsights?callbackUrl=';
    const user = useRecoilValue(authState);
    const [originalVideos, setOriginalVideos] = useState<any[]>([]);
    const [videos, setVideos] = useState<any[]>([]);
    const [record, setRecord] = useState({});
    const [view, setView] = useState('GRID');
    const [processing, setProcessing] = useState(false);
    const [adminUser, setAdminUser] = useState(false);
    const [filters, setFilters] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            if (user) {
                refreshVideos();
                const resp = await api.get(`store/knowledge-center-users/${user.username}`);
                setAdminUser(resp.data?.role === 'ADMIN');
            }
        })();
    }, [user]);

    useEffect(() => {
        applyFilters(originalVideos);
    }, [filters]);

    const moduleMap = {
        'Research': 'blue',
        'Sales': 'olive',
        'Sales Ops': 'violet',
        'Product & Technology': 'green',
        'Client Success Team': 'orange',
        'Management': 'teal',
        'Everyone': 'purple'
    };

    const filterOptions = [
        {key: 'Research', text: 'Research', value: 'Research'},
        {key: 'Sales', text: 'Sales', value: 'Sales'},
        {key: 'Sales Ops', text: 'Sales Ops', value: 'Sales Ops'},
        {key: 'Product & Technology', text: 'Product & Technology', value: 'Product & Technology'},
        {key: 'Client Success Team', text: 'Client Success Team', value: 'Client Success Team'},
        {key: 'Management', text: 'Management', value: 'Management'},
        {key: 'Everyone', text: 'Everyone', value: 'Everyone'}
    ];

    const applyFilters = (items: any[]) => {
        if (filters?.length > 0) {
            setVideos(items.filter(item => filters.indexOf(item.module) !== -1));
        } else {
            setVideos(items);
        }
    };

    const refreshVideos = async () => {
        setProcessing(true);
        const resp = await api.get('store/knowledge-center/videos/list');
        const items = orderBy(resp.data, ['module', 'updatedOn'], ['asc', 'desc']);
        const promises = items.map(item => api.get(`store/knowledge-center/${item.id.replace('knowledge-center:', '')}/payload`).catch(_ => null));
        const payloadResps = await Promise.all(promises);
        payloadResps.map((item: any, index) => items[index].thumbnail = item?.data);
        setOriginalVideos(items);
        applyFilters(items);
        setProcessing(false);
    };

    const showNewVideoForm = () => {
        setView('CREATE');
        setRecord({
            key: Date.now()
        });
    };

    const hideForm = async () => {
        setView('GRID');
        await refreshVideos();
    };

    const editVideo = (event, video) => {
        event.stopPropagation();
        event.preventDefault();
        setView('CREATE');
        setRecord(video);
    };

    const deleteVideo = async (event, video) => {
        event.stopPropagation();
        event.preventDefault();
        if (window.confirm('Are you sure you want to delete this video?')) {
            await api.delete(`store/knowledge-center/videos:${video.key}`);
            await refreshVideos();
        }
    };

    const handleFilterChange = (event, data) => {
        setFilters(data.value);
    };

    const parseVideoUrl = url => {
        if (url.indexOf('easygenerator.com') !== -1) {
            return targetUrl + url;
        }

        return url;
    };

    const renderActions = () => {
        if (['GRID', 'LIST'].indexOf(view) !== -1) {
            return (
                <>
                    <Dropdown className="module-filter" placeholder="Module Filter ..." fluid multiple selection options={filterOptions} onChange={handleFilterChange}></Dropdown>
                    {adminUser && <button className="ui primary button" onClick={showNewVideoForm}><i className="plus circle icon"></i> Add</button>}
                    <div className="ui buttons">
                        <button className={'ui button ' + (view === 'GRID' && 'primary')} onClick={() => setView('GRID')}>Grid</button>
                        <div className="or"></div>
                        <button className={'ui button ' + (view === 'LIST' && 'primary')} onClick={() => setView('LIST')}>List</button>
                    </div>
                </>
            );
        }
    };

    const renderView = () => {
        if (processing) {
            return <Spinner color="#2185d0" size="75px"/>;
        } else {
            const options = {
                videos, adminUser, moduleMap, parseVideoUrl, deleteVideo, editVideo
            }

            switch (view) {
                case 'CREATE':
                    return <KnowledgeCenterForm record={record} filterOptions={filterOptions} hideForm={hideForm}/>;
                case 'GRID':
                    return <KnowledgeCenterGrid {...options}/>;
                case 'LIST':
                    return <KnowledgeCenterList {...options}/>;
            }
        }
    };

    return (
        <div className="knowledge-center-wrapper">
            <div className="section-title">
                <div className="label">Knowledge Center</div>
                <div className="actions">
                    {renderActions()}
                </div>
            </div>
            <div className="scroll-ctx">
                {renderView()}
            </div>
        </div>
    )
}

export default KnowledgeCenter;
