import jwt_decode from "jwt-decode";
import api from "../apis/platform.api";

export const signIn = async ({ username, password }) => {
    const resp = await api.post("/user/login", {
        username,
        password,
    });

    const token = resp.data;

    setToken(token);
    return decode(token);
};

export const autoLogin = async () => {
    try {
        const response = await api.post("/user/login-lte", undefined, {
            withCredentials: true,
        });

        const token = response.data;
        setToken(token);
        return decode(token);
    } catch (e) {
        console.error("Not able to auto-login", e);
    }

    return null;
};

export const resolveCurrentUser = () => {
    const token = getToken();

    if (token) {
        const user: any = decode(token);

        if (user.exp > Date.now() / 1000) {
            return user;
        }
    }

    return null;
};

export const getToken = () => {
    return localStorage.getItem("cs-platform-token");
};

export const setToken = (token) => {
    localStorage.setItem("cs-platform-token", token);
};

export const clearToken = async () => {
    localStorage.removeItem("cs-platform-token");
    await api.post("/user/logout");
};

export const decode = (token) => {
    return jwt_decode(token);
};