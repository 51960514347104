import classNames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { signIn } from "../apis/auth.api";
import { USER_CREDENTIALS_NOT_CORRECT, NO_APPLICATIONS_ACCESS } from "../apis/error.states";
import authState from "../atoms/auth.atoms";
import LoginForm from "../types/login.types";
import { ENV_NAME } from "../utils/path.utils";

import './Login.scss';
import Logo from "./header/Logo";

function Login() {

    const [pending, setPending] = useState(false);
    const [loginMessage, setLoginMessage] = useState('');
    const setAuth = useSetRecoilState(authState);
    const { register, handleSubmit, formState: { errors } } = useForm<LoginForm>();

    const renderErrorClass = (field: any) => {
        return classNames({
            field: true,
            error: field != null
        });
    };

    const renderErrorMessage = () => {
        switch (loginMessage) {
            case USER_CREDENTIALS_NOT_CORRECT:
                return 'Username/Password are incorrect';
            case NO_APPLICATIONS_ACCESS:
                return (<>You don't have access to this application.<br />If you think this is an error please email <a href="mailto:product@creditsights.com">product@creditsights.com</a></>)
            default:
                return undefined;
        }
    };

    const renderError = () => {
        if (loginMessage) {
            return <div className="ui error message">{renderErrorMessage()}</div>;
        }
    }

    const onSubmit = async (values: any) => {
        setLoginMessage('');
        setPending(true);
        try {
            const user = await signIn(values);
            setAuth(user);
        } catch (e) {
            const error = e as { response: { data: { message: string } } };
            setLoginMessage(error.response.data.message);
        }
        setPending(false);
    };

    const renderEnvName = () => {
        if (ENV_NAME) {
            return (
                <div className="env-name-ctx">
                    <span className="env-name">{ENV_NAME}</span>
                </div>
            )
        }
    };

    return (
        <div className="login-wrapper">
            <div className="ui card" style={{backgroundColor:'#174174'}}>
                <div className="image-wrapper">
                    
                    <Logo/>
                </div>
                <div className="header-wrapper">
                    <i className="user circle icon"></i>
                </div>
                {renderEnvName()}
                <div className="content">
                    <form className="ui form error" onSubmit={handleSubmit(onSubmit)}>
                        <div className={renderErrorClass(errors.username)}>
                            <label>Username</label>
                            <input placeholder="Username" {...register("username", { required: true })} />
                            {errors.username && <div className="ui pointing red basic label">Username is required</div>}
                        </div>
                        <div className={renderErrorClass(errors.password)}>
                            <label>Password</label>
                            <input placeholder="Password"   type="password" {...register("password", { required: true })} />
                            {errors.password && <div className="ui pointing red basic label">Password is required</div>}
                        </div>
                        {renderError()}
                        <button className="  fluid button" type="submit" disabled={pending}>Submit</button>
                        <div className="forgot">
                            Forgot <a href="https://www.creditsights.com/login/forgot/username">Username</a> or <a href="https://www.creditsights.com/login/forgot">Password</a>?
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
