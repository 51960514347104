import {orderBy} from "lodash";
import {atom, selector} from "recoil";
import {fetchApplications} from "../apis/user.api";
import authState from "./auth.atoms";

export const appsState = selector<any[]>({
    key: "appsState",
    get: async ({get}) => {
        const user = get(authState);
        const apps = !user ? [] : await fetchApplications();
        return orderBy(apps, a => a.name);
    }
});

export const uiAppsState = selector<any[]>({
    key: "uiAppsState",
    get: async ({get}) => {
        const sites = get(appsState);
        return sites.filter((site) => site.web);
    }
});

export const selectedAppIdState = atom<string>({
    key: 'selectedAppIdState',
    default: ''
});

export const selectedAppState = selector<any>({
    key: 'selectedAppState',
    get: ({get}) => {
        const apps = get(uiAppsState);
        const id = get(selectedAppIdState);
        let app = apps.find(app => app.app_id === id);

        if (app) {
            localStorage.setItem('APP_ID', id);
        }

        return app || apps[0];
    }
});

export const selectedAppLoadTimeState = atom<number>({
    key: 'selectedAppLoadTimeState',
    default: Date.now()
});
