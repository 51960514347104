import { useEffect, useState, forwardRef } from "react";
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { fetchEntities } from "../../apis/acl.api";
import { getDefaultOrder } from "../../services/acl.service";

export type SelectProps = {
    value,
    placeholder?,
    options?,
    entityType?,
    entityProperty?,
    required?,
    disabled?,
    onChange?,
    onBlur?
}

export const Select = forwardRef((props: SelectProps, ref: any) => {
    const {
        value,
        placeholder,
        options = [],
        entityType,
        entityProperty = "id",
        disabled,
        onChange,
        onBlur,
    } = props;

    const [loading, setLoading] = useState(false);
    const [dropdownItems, setDropdownItems] = useState<DropdownItemProps[]>([]);
    const lookup = entityType ? getDefaultOrder(entityType) : "name";

    useEffect(() => {
        (async () => {
            await populateDropdown();
        })();
    }, [entityType]);

    const populateDropdown = async () => {
        if (entityType !== undefined) await populateDropdownWithEntities();
        else populateDropdownWithOptions();
    };

    const handleChange = (_, data: DropdownProps) => {
        if (!data.value)
            onChange(null);
        else
            onChange(data.value);
    }

    const populateDropdownWithEntities = async () => {
        setLoading(true);
        const entities = await fetchEntities(entityType, {
            limit: 1000,
            sort: lookup,
        });
        const items = entities.map((e) => {
            return {
                key: e.id,
                value: e[entityProperty],
                text: e[lookup],
            };
        });
        setLoading(false);
        setDropdownItems(items);
    };

    const populateDropdownWithOptions = () => {
        const items = options?.map((option) => ({
            key: option,
            value: option,
            text: option,
        }));
        setDropdownItems(items);
    };

    return (
        <Dropdown
            selection
            search
            loading={loading}
            selectOnBlur={false}
            selectOnNavigation={false}
            clearable={!disabled}
            value={value}
            options={dropdownItems}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            ref={ref}
        />
    );
});
